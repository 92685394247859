.sign-side-operation {
  position: fixed;
  top: 4.666667rem;
  right: 0.64rem;
  z-index: 102;
  border: 0.026667rem solid #609BFF;
  border-radius: 0.106667rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.213333rem 0 rgba(0, 0, 0, 0.12);
}

.sign-side-operation.transparent {
  opacity: 0.6;
}

.sign-side-operation.toggle-transparent {
  -webkit-animation: toggle-opacity 1.5s linear;
  animation: toggle-opacity 1.5s linear;
}

@-webkit-keyframes toggle-opacity {
  0% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes toggle-opacity {
  0% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.sign-side-operation .title {
  padding: 0.106667rem 0.16rem;
  width: 101%;
  border-radius: 0;
  border-top-right-radius: 0.106667rem;
  background: #609BFF;
  line-height: 0.426667rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #FFFFFF;
}

.sign-side-operation .main {
  position: relative;
  transition: all 0.3s ease-out;
}

.sign-side-operation .main .tips_wrapper {
  position: absolute;
  left: -5.386667rem;
  top: 0.266667rem;
  padding: 0.213333rem;
  border-radius: 0.106667rem;
  background: #4C4C4C;
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.9);
}

.sign-side-operation .main .tips_wrapper .tips {
  position: relative;
}

.sign-side-operation .main .tips_wrapper .tips::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -0.266667rem;
  z-index: -1;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  width: 0.150849rem;
  height: 0.150849rem;
  background: #4C4C4C;
}

.sign-side-operation .main .operation_btn {
  position: relative;
  align-items: center;
  padding: 0.213333rem 0;
}

.sign-side-operation .main .operation_btn:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.026667rem;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.sign-side-operation .footer {
  padding: 0.213333rem 0.373333rem;
  font-size: 0.32rem;
  color: #609BFF;
}