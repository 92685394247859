.contact-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding-top: 0.853333rem;
}

.contact-wrap .logo-i {
  width: 2.08rem;
  height: 2.08rem;
}

.contact-wrap .title {
  font-size: 0.533333rem;
  font-weight: 600;
  margin-top: 0.213333rem;
}

.contact-wrap .call {
  font-size: 0.586667rem;
  font-weight: 600;
  margin-top: 1.44rem;
}

.contact-wrap .hint {
  font-size: 0.373333rem;
  opacity: 0.6;
  margin-top: 0.213333rem;
}