.react-pdf__Page {
  box-shadow: 0 0 0.16rem rgba(0, 0, 0, 0.3);
}

#pdfView-previous {
  position: relative;
}

.sign-img-bg-previous {
  position: absolute;
  z-index: 7;
  padding-right: 0.56rem;
}

.sign-img-bg-previous.bg {
  outline: 0.026667rem dashed #ffdfdf;
}

.sign-img-bg-previous.bg::after {
  content: "个人签";
  width: 0.48rem;
  font-size: 0.266667rem;
  color: #ff6060;
  display: block;
  font-weight: 600;
  background: #ffdfdf;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  text-align: center;
}