.operations-wrappper-previous {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 0.373333rem;
  z-index: 8;
  width: 100%;
  box-shadow: 0 -0.053333rem 0.106667rem 0 rgba(0, 0, 0, 0.05);
}

.operations-wrappper-previous .operations {
  height: 1.306667rem;
  display: flex;
  align-items: center;
}

.operations-wrappper-previous .operations .line {
  width: 0.026667rem;
  height: 0.64rem;
  background: rgba(54, 60, 90, 0.2);
}

.operations-wrappper-previous .operations .btn {
  border: none;
  outline: none;
  color: #363c5a;
  height: 100%;
  background: transparent;
  font-weight: 600;
}

.operations-wrappper-previous .operations .white-btn {
  flex: 1 1;
}

.operations-wrappper-previous .operations .blue-btn {
  width: 3.146667rem;
  background: #609bff;
  color: #fff;
}

.page-count-previous {
  position: fixed;
  padding: 0 0.213333rem;
  height: 0.533333rem;
  line-height: 0.533333rem;
  background: #eaebee;
  color: #363c5a;
  top: 0.426667rem;
  right: 0.426667rem;
  font-size: 0.32rem;
  border-radius: 0.266667rem;
}

.arrow {
  width: 1.92rem;
  height: 1.92rem;
  position: fixed;
  right: 0.213333rem;
}

.arrow.up {
  bottom: 5.093333rem;
}

.arrow.down {
  bottom: 3.173333rem;
}