.board-comp-handwrite {
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 50%;
  background: #fff;
  transition: 0.3s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.853333rem 0.906667rem 0 0;
}

.board-comp-handwrite .my-toast-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 101;
}

.board-comp-handwrite .my-toast {
  background-color: rgba(58, 58, 58, 0.9);
  padding: 0 0.4rem;
  font-size: 0.373333rem;
  color: #fff;
  position: absolute;
  border-radius: 0.106667rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -0.906667rem;
  height: 0.906667rem;
  line-height: 0.906667rem;
  z-index: 102;
  transition: 0.1s;
}

.board-comp-handwrite .my-toast.translate {
  top: 10%;
}

.board-comp-handwrite .title-i {
  width: 4.373333rem;
  height: 1.12rem;
}

.board-comp-handwrite .sign-area {
  flex: 1 1;
  width: 100%;
  position: relative;
}

.board-comp-handwrite .sign-area::before {
  content: "请在此处签名";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 2.133333rem;
  color: #f5f5f7;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.board-comp-handwrite .sign-area canvas {
  position: relative;
  z-index: 100;
}

.board-comp-handwrite .operation-wrap {
  height: 1.706667rem;
  padding: 0 1.813333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.board-comp-handwrite .operation-wrap .color-select {
  display: flex;
}

.board-comp-handwrite .operation-wrap .color-select .color-box {
  margin-right: 0.64rem;
}

.board-comp-handwrite .operation-wrap .color-select .color-box:last-child {
  margin-right: 0;
}

.board-comp-handwrite .operation-wrap .color-select .color-box .check-i {
  width: 0.586667rem;
  height: 0.586667rem;
  border-radius: 50%;
  display: block;
}

.board-comp-handwrite .operation-wrap .btns {
  display: flex;
  align-items: center;
}

.board-comp-handwrite .operation-wrap .btns .text-operation {
  font-size: 0.533333rem;
  color: #afb1bd;
  margin-right: 0.853333rem;
}

.board-comp-handwrite .operation-wrap .btns .sure-btn {
  width: 2.133333rem;
  height: 0.853333rem;
  background: #609bff;
  color: #fff;
  font-size: 0.373333rem;
  border-radius: 0.106667rem;
  border: none;
  outline: none;
}

.am-toast-notice.page_handwrite {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}