.result-container {
  padding: 0 1.013333rem;
  margin-top: 2.08rem;
}

.result-container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-container .content img {
  height: 2.026667rem;
  width: 2.24rem;
}

.result-container .content div {
  width: 100%;
  text-align: center;
}

.result-container .content .result {
  margin: 0.373333rem 0 0.213333rem;
  font-size: 0.48rem;
  color: #609BFF;
  font-weight: bold;
}

.result-container .content .success {
  color: #609BFF;
}

.result-container .content .ing {
  color: #23BCCA;
}

.result-container .content .err {
  color: #363C5A;
}

.result-container .content .mes {
  font-size: 0.373333rem;
  color: #363C5A;
  opacity: 0.6;
}

.result-container .content .errMes {
  color: #FF7171;
  opacity: 1;
}

.result-container .back-btn {
  background-color: #363C5A;
  color: white;
  font-size: 0.373333rem;
  height: 1.28rem;
  width: 100%;
  border-radius: 0.64rem;
  text-align: center;
  line-height: 1.28rem;
  margin-top: 2.72rem;
  margin-bottom: 0.426667rem;
}

.result-container .second-box {
  height: 1.28rem;
  width: 100%;
  border: 0.026667rem solid rgba(79, 78, 107, 0.2);
  color: #363C5A;
  font-weight: bold;
  border-radius: 0.64rem;
  line-height: 1.28rem;
  text-align: center;
  font-size: 0.373333rem;
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}

.mask img {
  width: 7.146667rem;
  height: 5.146667rem;
  position: absolute;
  right: 0;
  top: 0;
}