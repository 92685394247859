.pwd-content {
  padding: 0.213333rem 0 0.48rem;
  color: #363c5a;
}

.pwd-content .content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.28rem;
  position: relative;
  padding: 0 0.64rem;
}

.pwd-content .content-title .cancel {
  opacity: 0.4;
}

.pwd-content .content-title > h1 {
  font-size: 0.426667rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 500;
}

.pwd-content .title {
  font-size: 0.64rem;
  font-weight: 600;
}

.pwd-content .hint {
  font-size: 0.373333rem;
  opacity: 0.6;
}

.pwd-content .ipt-wrap {
  margin-top: 0.64rem;
}

.pwd-content .cut-type {
  font-size: 0.426667rem;
  color: #609bff;
  font-weight: 600;
  margin-top: 0.746667rem;
}

.pwd-content .cut-type.mt52 {
  margin-top: 1.306667rem;
}

.pwd-content .btn {
  width: 9.146667rem;
  height: 1.173333rem;
  line-height: 1.173333rem;
  font-size: 0.426667rem;
  color: #fff;
  font-weight: 600;
  background: #363c5a;
  border-radius: 0.586667rem;
  margin-top: 0.693333rem;
}

.pwd-content .non-pwd-hint {
  font-size: 0.426667rem;
  font-weight: 600;
}

.pwd-content .to-set-btn {
  width: 2.56rem;
  height: 1.173333rem;
  background: #363c5a;
  border: 0.026667rem solid transparent;
  border-radius: 0.533333rem;
  font-size: 0.426667rem;
  font-weight: 600;
  color: #fff;
  margin-top: 0.426667rem;
}

.pwd-content .to-set-btn.confirm {
  width: 6.266667rem;
  border: 0.026667rem solid #E1E4EB;
  background: #fff;
  color: #363c5a;
}

.pwd-modal.am-modal-wrap-popup {
  display: flex;
}

.pwd-modal.am-modal-wrap {
  align-items: flex-end;
}

.pwd-modal .am-modal {
  height: 85% !important;
}