.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  min-height: 100vh;
  padding: 0.853333rem 0.426667rem 1.973333rem;
}

.about-wrapper > img {
  width: 2.08rem;
  height: 2.08rem;
}

.about-wrapper .logo-text {
  font-size: 0.533333rem;
  font-weight: 600;
  margin-bottom: 0.693333rem;
}

.about-wrapper .text {
  font-size: 0.373333rem;
  line-height: 0.64rem;
  margin-bottom: 0.426667rem;
}

.about-wrapper .text:last-child {
  margin-bottom: 0;
}

.about-wrapper .service-title {
  margin-top: 0.853333rem;
  width: 100%;
  text-align: left;
  font-size: 0.533333rem;
  font-weight: 600;
}

.about-wrapper .service-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.373333rem;
}

.about-wrapper .service-list > li {
  display: flex;
  align-items: center;
  margin-right: 0.853333rem;
  margin-bottom: 0.426667rem;
}

.about-wrapper .service-list > li > img {
  width: 0.213333rem;
  height: 0.213333rem;
  margin-right: 0.106667rem;
}