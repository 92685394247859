@-webkit-keyframes focusToggle {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes focusToggle {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.single-auto-focus-ipt {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.single-auto-focus-ipt .val-ipt {
  width: 3.2rem;
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  border: none;
  outline: none;
  background: transparent;
  opacity: 0;
}

.single-auto-focus-ipt .ipt-box {
  padding: 0 0.213333rem;
}

.single-auto-focus-ipt .ipt-box .ipt-value {
  width: 1.066667rem;
  height: 1.28rem;
  border: 0.026667rem solid rgba(54, 60, 90, 0.06);
  box-sizing: border-box;
  caret-color: #609bff;
  color: #363c5a;
  text-align: center;
  line-height: 1.28rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-auto-focus-ipt .ipt-box .ipt-value .focus {
  width: 0.026667rem;
  height: 0.746667rem;
  background: #609bff;
  -webkit-animation: focusToggle 1000ms linear infinite;
  animation: focusToggle 1000ms linear infinite;
}