.faq-wrapper {
  color: #363c5a;
  background: #f8f9fb;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.faq-wrapper .tab-list {
  display: flex;
  justify-content: space-between;
  padding: 0 0.426667rem;
  font-size: 0.373333rem;
  height: 1.173333rem;
  align-items: center;
  background: #fff;
}

.faq-wrapper .tab-list .tab-item {
  opacity: 0.4;
  height: 100%;
  display: flex;
  align-items: center;
}

.faq-wrapper .tab-list .tab-item.active {
  opacity: 1;
  font-weight: 600;
  color: #609bff;
  position: relative;
}

.faq-wrapper .tab-list .tab-item.active::after {
  content: "";
  display: block;
  width: 0.64rem;
  height: 0.053333rem;
  background: #609bff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.faq-wrapper .list-wrap {
  flex: 1 1;
  padding-bottom: 0.96rem;
  font-size: 0.373333rem;
  overflow: scroll;
  /*样式覆盖*/
}

.faq-wrapper .list-wrap .am-accordion-header {
  color: #363c5a !important;
  font-size: 0.373333rem !important;
}

.faq-wrapper .list-wrap .content-wrap {
  padding: 0.426667rem;
  font-size: 0.373333rem;
  opacity: 0.8;
  line-height: 0.64rem;
  color: #363c5a;
}

.faq-wrapper .list-wrap .content-wrap > p {
  margin-bottom: 0.426667rem;
}

.faq-wrapper .list-wrap .content-wrap > p:last-child {
  margin-bottom: 0;
}