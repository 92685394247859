.seal-wrapper {
  padding: 0.426667rem;
  background: #fff;
}

.seal-wrapper .seal-box {
  height: 4.266667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.seal-wrapper .seal-box .seal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
}

.seal-wrapper .seal-box .seal > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.seal-wrapper .seal-box .seal .name,
.seal-wrapper .seal-box .seal .id {
  color: #ff6060;
  white-space: nowrap;
  line-height: 1;
}

.seal-wrapper .seal-box .seal .name {
  font-weight: 900;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}

.seal-wrapper .seal-box .seal .id {
  opacity: 0.6;
}

.seal-wrapper .btn {
  width: 100%;
  height: 1.173333rem;
  background: transparent;
  border: none;
  outline: none;
  border: 0.026667rem solid #363c5a;
  font-size: 0.426667rem;
  font-weight: 600;
  border-radius: 1.173333rem;
  margin-top: 0.853333rem;
}