.react-pdf__Page {
  box-shadow: 0 0 0.16rem rgba(0, 0, 0, 0.3);
}

#pdfView {
  position: relative;
}

.sign-widget {
  position: absolute;
  z-index: 100;
  line-height: 0.426667rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(54, 60, 90, 0.8);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.sign-widget-header {
  padding: 0.16rem 0.213333rem;
}

.sign-widget-content {
  position: relative;
  flex: 1 1;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0.106667rem;
}

.sign-widget-content-type {
  position: absolute;
  bottom: 0.213333rem;
  right: 0.213333rem;
}

.sign-widget.person {
  background: rgba(252, 134, 26, 0.1);
}

.sign-widget.person .sign-widget-content {
  border: 0.026667rem dashed #FC861A;
}

.sign-widget.enterprise {
  background: rgba(96, 155, 255, 0.1);
}

.sign-widget.enterprise .sign-widget-content {
  border: 0.026667rem dashed #609BFF;
}

.sign-widget .date {
  scale: 1.3;
  color: #F44C4C;
}

.page-count {
  position: fixed;
  bottom: 2.666667rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 0.106667rem 0.426667rem;
  border-radius: 0.32rem;
  background: rgba(0, 0, 0, 0.8);
  font-size: 0.32rem;
  color: #fff;
}

.page-count.ios {
  bottom: 4.48rem;
}