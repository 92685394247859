.seal-sign-modal {
  position: relative;
  padding: 0.426667rem 0.426667rem 0.64rem;
}

.seal-sign-modal .icon-close {
  position: absolute;
  top: 0.426667rem;
  right: 0.426667rem;
}

.seal-sign-modal .title {
  line-height: 0.64rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #363C5A;
}

.seal-sign-modal .sign-count {
  align-self: flex-start;
  margin-left: 0.853333rem;
  line-height: 0.426667rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #609BFF;
}

.seal-sign-modal .sign-item {
  padding: 0.426667rem 0;
  border: 0.026667rem solid #E1E4EB;
  border-radius: 0.106667rem;
  background: #FFFFFF;
}

.seal-sign-modal .sign-item:has(.btn-create-seal) {
  padding: 0.906667rem 0.426667rem;
}

.seal-sign-modal .sign-item .btn-create-seal {
  padding: 0.32rem 1.146667rem;
  background: #609BFF;
  border-radius: 0.106667rem;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
  font-weight: 500;
  color: #FFFFFF;
}