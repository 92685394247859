.parent-wrapper {
  position: relative;
}

.sign {
  position: absolute;
}

.am-modal-content {
  border-top-left-radius: 0.853333rem;
  border-top-right-radius: 0.853333rem;
}

.quick-sign-modal-wrapper .am-modal-transparent,
.seal-sign-modal-wrapper .am-modal-transparent,
.date-sign-modal-wrapper .am-modal-transparent,
.create-seal-modal-wrapper .am-modal-transparent {
  width: 8.533333rem;
}

.quick-sign-modal-wrapper .am-modal-content,
.seal-sign-modal-wrapper .am-modal-content,
.date-sign-modal-wrapper .am-modal-content,
.create-seal-modal-wrapper .am-modal-content {
  padding-top: 0;
  border-radius: 0.106667rem;
}

.quick-sign-modal-wrapper .am-modal-body,
.seal-sign-modal-wrapper .am-modal-body,
.date-sign-modal-wrapper .am-modal-body,
.create-seal-modal-wrapper .am-modal-body {
  padding: 0 !important;
}

.modal-alert-title {
  line-height: 0.64rem;
  font-size: 0.453333rem;
  font-weight: bold;
  color: #000000;
}