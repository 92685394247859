.operations-wrappper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  align-items: flex-end;
  padding: 0 0.426667rem;
  width: 100%;
  background: #fff;
  box-shadow: 0 -0.053333rem 0.106667rem 0 rgba(0, 0, 0, 0.05);
  font-size: 0.373333rem;
}

.operations-wrappper .operations-wrappper-sub.ios {
  background: #fff;
  padding-bottom: 0.906667rem;
}

.operations-wrappper .btn_page {
  margin-bottom: 0.106667rem;
  padding: 0.32rem 0.32rem 0.32rem 0.266667rem;
  border: 0.026667rem solid #E1E4EB;
  border-radius: 0.106667rem;
  background: #FFFFFF;
  line-height: 0.426667rem;
  font-size: 0.32rem;
  color: #363C5A;
}

.operations-wrappper .btn_page.disabled {
  background: #F6F8FB;
}

.operations-wrappper .btn_page.disabled button {
  color: rgba(54, 60, 90, 0.4);
}

.operations-wrappper .btn_page:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.operations-wrappper .btn_page:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.operations-wrappper .btn_done {
  padding: 0.266667rem 1.706667rem;
  border-radius: 0.586667rem;
  background: #363C5A;
  line-height: 0.64rem;
  font-size: 0.426667rem;
  font-weight: 500;
  color: #FFFFFF;
}

.operations-wrappper .btn_done.disabled {
  background: rgba(54, 60, 90, 0.3);
}