* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #363c5a;
}

ul,
ol,
li {
  list-style: none;
}

button,
input {
  border: none;
  outline: none;
  background: transparent;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.flex-c-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1 1;
}

.ml-4 {
  margin-left: 0.106667rem;
}

.ml-6 {
  margin-left: 0.16rem;
}

.ml-8 {
  margin-left: 0.213333rem;
}

.ml-12 {
  margin-left: 0.32rem;
}

.mt-4 {
  margin-top: 0.106667rem;
}

.mt-8 {
  margin-top: 0.213333rem;
}

.mt-12 {
  margin-top: 0.32rem;
}

.mt-16 {
  margin-top: 0.426667rem;
}

.icon-16 {
  width: 0.426667rem;
  height: 0.426667rem;
}

.icon-20 {
  width: 0.533333rem;
  height: 0.533333rem;
}

.icon-24 {
  width: 0.64rem;
  height: 0.64rem;
}

.text-ellipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}