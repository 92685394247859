.parent-wrapper-previous {
  position: relative;
}

.sign {
  position: absolute;
}

.am-modal-content {
  border-top-left-radius: 0.853333rem;
  border-top-right-radius: 0.853333rem;
}