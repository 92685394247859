.create-seal-modal {
  position: relative;
  padding: 0.426667rem calc(0.853333rem / 2) 0.64rem;
}

.create-seal-modal .icon-close {
  position: absolute;
  top: 0.426667rem;
  right: 0.426667rem;
}

.create-seal-modal .title {
  line-height: 0.64rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #363C5A;
}

.create-seal-modal .content {
  position: relative;
  left: -0.426667rem;
  align-items: center;
  padding: 0.64rem 0;
  width: calc(100% + 0.853333rem);
  background: rgba(244, 76, 76, 0.1);
}

.create-seal-modal .content .seal {
  position: relative;
  justify-content: center;
}

.create-seal-modal .content .seal .icon-seal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.create-seal-modal .content .seal p {
  color: #ff6060;
}

.create-seal-modal .content .desc {
  line-height: 0.426667rem;
  font-size: 0.32rem;
  color: rgba(54, 60, 90, 0.8);
}

.create-seal-modal .btn-create {
  align-self: center;
  padding: 0.266667rem 1.813333rem;
  border-radius: 0.586667rem;
  background: #363C5A;
  line-height: 0.64rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #FFFFFF;
}

.create-seal-modal .tips {
  text-align: left;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
  color: rgba(54, 60, 90, 0.8);
}

.create-seal-modal .tips > p {
  line-height: 0.426667rem;
  font-size: 0.32rem;
  color: rgba(54, 60, 90, 0.6);
}