.jump-weapp-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jump-weapp-wrapper .jump-title-icon {
  width: 4.906667rem;
  height: 1.44rem;
}

.jump-weapp-wrapper .jump-content-icon {
  width: 7.626667rem;
  height: 6.4rem;
  margin-top: 1.44rem;
}

.jump-weapp-wrapper .btn {
  width: 7.013333rem;
  height: 1.173333rem;
  color: #ffffff;
  font-size: 0.426667rem;
  font-weight: 600;
  background: #363c5a;
  border-radius: 0.586667rem;
  margin-top: 1.493333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jump-weapp-wrapper .btn > img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-right: 0.213333rem;
}