.quick-sign-modal {
  position: relative;
  padding: 0.426667rem 0.426667rem 0.64rem;
}

.quick-sign-modal .icon-close {
  position: absolute;
  top: 0.426667rem;
  right: 0.426667rem;
}

.quick-sign-modal .title {
  line-height: 0.64rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #363C5A;
}

.quick-sign-modal .title .sign-count {
  line-height: 0.426667rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #609BFF;
}

.quick-sign-modal .tips {
  align-self: flex-start;
  font-size: 0.373333rem;
  color: rgba(54, 60, 90, 0.6);
}

.quick-sign-modal .sign-item {
  padding: 0.32rem 0.426667rem 0.426667rem;
  border: 0.026667rem solid #E1E4EB;
  border-radius: 0.106667rem;
  background: #FFFFFF;
}

.quick-sign-modal .sign-item .btn-create-seal,
.quick-sign-modal .sign-item .btn-handwrite {
  padding: 0.32rem 1.813333rem 0.32rem 1.813333rem;
  border-radius: 0.106667rem;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
  font-weight: 500;
}

.quick-sign-modal .sign-item .btn-create-seal {
  background: #609BFF;
  color: #FFFFFF;
}

.quick-sign-modal .sign-item .btn-handwrite {
  padding: 0.32rem 2.56rem 0.32rem;
  border: 0.026667rem solid #363C5A;
  background: #FFFFFF;
  color: #363C5A;
}